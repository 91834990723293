<template>
  <div :data-testid="dataTestid" :id="'otp-'+ randomId">
    <div :class="$attrs.class || twMerge('flex justify-center mb-2 space-x-2 rtl:space-x-reverse',appendClass)">
      <template v-for="(value, index) in Number(length)" :key="index">
        <slot>
          <TsInput
              v-model="otpInputs[index]"
              :id="`otp-${randomId}-${index}`"
              :type="inputType"
              :maxLength="1"
              :variant="props.variant"
              :appendClass="twMerge([
                  'max-w-min py-0',
                  props.appendClass
              ])"
              :inputClass="twMerge([
                  'w-6 text-center py-2.5',
                  props.inputClass
              ])"
              size="sm"
              @input="onInput($event, index)"
              @paste="onPaste($event)"
              :disabled="$attrs.disabled || props.loading"
          />
        </slot>
      </template>
    </div>
    <p
      :data-testid="dataTestid+'-para'"
      id="helper-text-explanation"
      :class="
        twMerge(
          'mt-2 text-[12px] text-center',
          helper_text_classes[variant.toLowerCase()],
          props.helperClass
        )
      "
    >
      <span v-if="loading" class="loader">
        <span>Verifying the OTP...</span>
      </span>
      <span v-else>{{
        message ||
        `Please enter the ${length} digit code we sent via email.`
      }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { ref, withDefaults, onMounted } from "vue";
import {useRandomUUID} from "../../composables/useRandomUUID";

const randomId: Ref<string> = ref("");
const otpInputs: Ref<string[]> = ref([]);

const activeInput = ref(0);
const model = defineModel();

onMounted(() => {
  randomId.value = useRandomUUID();
});

enum Variants {
  Default= 'default',
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
}
enum Type  {
  text = 'text',
  number = 'number'
}

type Props = {
  variant?: Variants,
  length?: number,
  message?: string,
  loading?: boolean,
  appendClass?: string,
  inputClass?: string,
  helperClass?: string,
  type?: Type,
  dataTestid?:string,
}

const inputType = 'text';

const props = withDefaults(defineProps<Props>(), {
  variant: Variants.Default,
  length: 6,
  loading: false,
  type: 'text',
  dataTestid:'molecule-otpinput'
})

defineOptions({
  inheritAttrs: true,
});

const helper_text_classes = {
  'default': 'text-natural-grey',
  'primary': 'text-primary dark:text-info-dark',
  'success': 'text-success  dark:text-success-dark',
  'danger': 'text-danger dark:text-danger-dark',
}

const handleModelValue = () => {
  model.value = otpInputs.value.join('').trim();
}

const onInput = (event: InputEvent, index: number) => {
  if(props.type == Type.number){
    const pattern = /[^\d.-]/g;
    if(pattern.test(event.target.value)){
      event.target.value = '';
      return;
    }
  }
  otpInputs.value[index] = event.target.value;
  handleModelValue();

  if (event.inputType === 'deleteContentBackward') {
    moveToPrev(event, index);
  } else if (event.inputType === 'insertText' || event.inputType === 'deleteContentForward') {
    moveToNext(event, index);
  }

};

const onFocus = () => {

}

const onBlur = () => {

}


const moveToNext = (event, index) => {
  let nextInput = document.getElementById(`otp-${randomId.value}-${index + 1}`);

  if (nextInput) {
    nextInput.focus();
  }
}

const moveToPrev = (event, index) => {
  let prevInput = document.getElementById(`otp-${randomId.value}-${index - 1}`);

  if (prevInput) {
    prevInput.focus();
  }
}

const onPaste = (event) => {
  var paste = event.clipboardData.getData('text');

  if(props.type == Type.number){
    const pattern = /[^\d.-]/g;
    paste = event.clipboardData.getData('text').replace(pattern , '');
  }


  if (paste.length) {
    let pastedCode = paste.substring(0, props.length);

    otpInputs.value = pastedCode.split('');

    handleModelValue();
    let nextInput = document.getElementById(`otp-${randomId.value}-${pastedCode.length}`);
    if(nextInput){
      nextInput.focus();
    }
  }

  event.preventDefault();
}

</script>
